@use '@fixed-size' as *;
@use '@queries' as *;

.container {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: var(--cornerradius10);

  @include singleFixedSize(24px);

  &-secondary {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.03);
    outline: 2px solid rgba(246, 246, 246, 1);
    outline-offset: -2px;
    background: #fff;
    border-radius: var(--cornerradius8);

    @include singleFixedSize(26px);

    @include min-1440-break {
      @include singleFixedSize(36px);
    }

    .btn {
      @include singleFixedSize(18px);

      @include min-1440-break {
        @include singleFixedSize(24px);
      }
    }
  }
}

.btn {
  @include singleFixedSize(24px);

  @include min-1440-break {
    @include singleFixedSize(24px);
  }

  &-hovered {
    opacity: 0.6;
    color: #eb3c3c;
  }
  &-active {
    opacity: 1;
    color: #eb3c3c;
  }
}
